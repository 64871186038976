import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage11() {
  const question = {
    title: 'Frage 11 von 35',
    headline: '',
    subline: 'Aufgabenvielfalt/Aufgabenspektrum',
  }

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="range"
              color="turkis"
              from="Ich mag ein überschaubares Arbeitsspektrum"
              to="Ich liebe es, unterschiedliche Aufgaben zu haben"
              index={11}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-12`}
          prev={`${AREA_CARE_PATH}/spielen/frage-10`}
          index={11}
        />
      </main>
    </>
  )
}
